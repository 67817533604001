// extracted by mini-css-extract-plugin
export var box = "PropertiesBoxAndDescription-module--box--f1eb0";
export var boxHeading = "PropertiesBoxAndDescription-module--boxHeading--74ac4";
export var boxIcon = "PropertiesBoxAndDescription-module--box-icon--0ac20";
export var boxRow = "PropertiesBoxAndDescription-module--box-row--0baf8";
export var column = "PropertiesBoxAndDescription-module--column--01284";
export var container = "PropertiesBoxAndDescription-module--container--13faf";
export var content = "PropertiesBoxAndDescription-module--content--87ae4";
export var flex = "PropertiesBoxAndDescription-module--flex--6f666";
export var fullWidth = "PropertiesBoxAndDescription-module--full-width--601d5";
export var hidden = "PropertiesBoxAndDescription-module--hidden--5f199";
export var leftColumn = "PropertiesBoxAndDescription-module--left-column--491d4";
export var pattern = "PropertiesBoxAndDescription-module--pattern--88443";
export var rightColumn = "PropertiesBoxAndDescription-module--right-column--77f6e";
export var section = "PropertiesBoxAndDescription-module--section--3ac61";
export var title = "PropertiesBoxAndDescription-module--title--dac6e";