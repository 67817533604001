import React from "react";
import ReactHtmlParser from "html-react-parser";

import Pattern from "../../components/Pattern/Pattern";
import Heading from "../../components/Typography/Heading/Heading";
import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import { cn } from "../../utils";
import * as styles from "./PropertiesBoxAndDescription.module.scss";
import IPropertiesBoxAndDescription from "./PropertiesBoxAndDescription.type";

const PropertiesBoxAndDescription = (props: IPropertiesBoxAndDescription) => {
    return (
        <section className={styles.section}>
            <div className={cn("container", styles.container)}>
                <Heading level="h2" className={styles.title}>
                    {props.sectionTitle}
                </Heading>
                <div
                    className={cn(
                        styles.column,
                        styles.leftColumn,
                        props.horizontalLayout && styles.fullWidth
                    )}
                >
                    <Pattern className={styles.pattern} />
                    <div
                        className={cn(
                            styles.box,
                            props.horizontalLayout && styles.flex
                        )}
                    >
                        {props.box.map((item) => (
                            <div key={item.heading} className={styles.boxRow}>
                                <div className={styles.boxIcon}>
                                    <img
                                        src={item.icon.localFile.publicURL}
                                        alt={item.icon.altText}
                                        className={styles.icon}
                                    />
                                </div>

                                <div className={styles.boxContent}>
                                    <h4 className={styles.boxHeading}>
                                        {item.heading}
                                    </h4>
                                    <div className={styles.content}>
                                        {ReactHtmlParser(item.description)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className={cn(
                        styles.column,
                        styles.rightColumn,
                        props.horizontalLayout && styles.hidden
                    )}
                >
                    <TitleUnderlineParagraph
                        heading={props.heading}
                        headingLevel="h2"
                        text={props.text}
                        underline="left"
                    />
                </div>
            </div>
        </section>
    );
};

export default PropertiesBoxAndDescription;
